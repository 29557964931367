import React from 'react';
import styled from 'styled-components';
import { TextBlockType } from '../../../../../types/CategoryAppType';
import BlockContent from '../../block-content/BlockContent';

type TextBlockProps = {
  block: TextBlockType;
};

const TextBlock = ({ block }: TextBlockProps) => (
  <TextBlockContainer className="TextBlock">
    <div className="TextBlockItem">
      <BlockContent value={block.text} />
    </div>
  </TextBlockContainer>
);

const TextBlockContainer = styled.div`
  & > :first-child,
  & > div > :first-child {
    margin-top: 0;
  }

  & > :last-child,
  & > div > :last-child {
    margin-bottom: 0;
  }
`;

export { TextBlock, TextBlockContainer };
